import { faArrowLeft, faArrowRight } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { ContentSectionArticle, useContentSections } from "../../hooks/content-sections"
import { ReactChildrenProps } from "../../types/ReactChildrenProps"

type Props = ReactChildrenProps & { sectionPath: string }
type RouteProps = Pick<ContentSectionArticle, "path" | "title">

const Pagination: React.FC<Props> = ({ sectionPath }) => {
  const { getContentForPath } = useContentSections()
  const [routes, setRoutes] = useState<{ [key: string]: RouteProps }>({
    previous: { path: "", title: "" },
    next: { path: "", title: "" },
  })

  useEffect(() => {
    const content = getContentForPath(sectionPath)

    if (!content) {
      return
    }

    const section = content.sections[content.sections.length - 1]
    const currentIndex = section.articles.findIndex(({ path }) => path === sectionPath)

    setRoutes({
      previous: section.articles[currentIndex - 1],
      next: section.articles[currentIndex + 1],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex justify-between mt-8">
      {routes.previous?.title ? (
        <Link to={routes.previous?.path} className="hover:text-tangerine">
          <FontAwesomeIcon icon={faArrowLeft} className="m-auto mr-2 " />
          {routes.previous?.title} (Previous Page)
        </Link>
      ) : (
        <div></div>
      )}

      {routes.next?.title ? (
        <Link to={routes.next?.path} className="hover:text-tangerine">
          {routes.next?.title} (Next Page)
          <FontAwesomeIcon icon={faArrowRight} className="m-auto ml-2" />
        </Link>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default Pagination

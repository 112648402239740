import React from "react"

import PageMetadata from "../../components/page/page-metadata"
import DocumentationLayout from "../../components/documentation/documentation-layout"
import { HeadingOne } from "../../components/common/typography"
import { MDXProvider } from "../mdx/mdx-provider"
import Pagination from "./pagination"

type Props = {
  title: string
  metaTitle?: string
  description?: string
  metaDescription?: string
  path: string
  children?: React.ReactNode
}

export const MDXContent = React.memo<Props>(({ title, metaTitle, description, metaDescription, path, children }) => {
  return (
    <div className="mx-6 md:mx-12 xl:mx-20">
      <MDXProvider>
        <PageMetadata title={metaTitle ?? title} description={metaDescription ?? description} />

        <DocumentationLayout path={path}>
          <div className="flex flex-col max-w-[760px]">
            <HeadingOne unanchored>{title}</HeadingOne>

            {children}

            <Pagination sectionPath={path} />
          </div>
        </DocumentationLayout>
      </MDXProvider>
    </div>
  )
})
